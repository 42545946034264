<template>
    <div class="settings">
        <div class="title">
            设置
        </div>
        <div class="box f-c-b" style="cursor: default;">
            <span>关于识料</span>
            <div>
                <span>版本信息</span>
                <span style="margin-left: 5px;">1.0.1</span>
            </div>
        </div>
        <div class="box f-c-b" @click="showDialog('agreement')">
            <span>用户协议和隐私政策</span>
            <i class="el-icon-arrow-right"></i>
        </div>
        <div class="box f-c-b SignOUT" @click="showDialog('SignOUT')">
            <span>注销识料账号</span>
            <i class="el-icon-arrow-right"></i>
        </div>
        <Dialog :title="title" width="1000px" :visible="dialogShow" @closeDialog="closeDialog"
            class="commonDialog">
            <div class="change-name f-cl-c" v-if="DialogType == 'agreement'">
                <UserAgreement></UserAgreement>
                <el-button @click="dialogShow = false" style="margin-top: 20px;" type="primary">关闭</el-button>
            </div>
            <div class="change-phone" v-else-if="DialogType == 'SignOUT'">
                <CancellationAgreement></CancellationAgreement>
                <div class="f-cl-c">
                    <el-checkbox v-model="checked"><span style="color: black !important;">我已阅读并同意</span><span
                            style="color: #0776F3;">《识料账号注销须知》</span></el-checkbox>
                    <el-button @click="verifyDeleteUser()" style="margin-top: 20px;" type="primary"
                        :disabled="!checked">注销</el-button>
                </div>
            </div>
        </Dialog>
    </div>
</template>
<script>
import UserAgreement from '@/pages/user/components/UserAgreement.vue';
import CancellationAgreement from '@/pages/user/components/CancellationAgreement.vue';
export default {
    components: { UserAgreement, CancellationAgreement },
    data() {
        return {
            title: '',
            DialogType: '',
            dialogShow: false,
            checked: false
        }
    },
    created() { },
    methods: {
        showDialog(data) {
            if (data == 'agreement') {
                this.title = '协议'
            } else if (data == 'SignOUT') {
                this.title = '注销账号'
            }
            this.DialogType = data;
            this.dialogShow = true;
        },
        // 关闭弹框
        closeDialog(data) {
            this.dialogShow = data;
        },
        verifyDeleteUser() {
            let that = this;
            that.$confirm('是否注销账号？', '提示').then(() => {
                let userId = that.$getStorage('userId'),
                    spType = that.$getStorage('spType'),
                    reflush = that.$getStorage('reflush');
                that.$http.deleteUser({
                    userId
                }).then(res => {
                    if (res.code == 200) {
                        that.dialogShow = false;
                        // if (that.$route.name == 'index') {
                        //     that.reload()
                        // }
                        that.$router.push('/home')
                        localStorage.clear();
                        that.$setStorage('spType', spType);
                        that.$setStorage('reflush', reflush);
                    } else if (res.code == 275) {
                        that.$common.message(res.msg, 'error')
                    }

                })
            }).catch(() => { })
        }
    }
}
</script>
<style lang="less" scoped>
.settings {
    .title {
        font-size: 24px;
        font-family: HarmonyOS Sans SC;
        color: #333333;
        line-height: 30px;
        padding-bottom: 24px;
        border-bottom: 1px solid #F2F2F2;
        font-weight: bold;
        text-align: left;
    }

    // .subheading {
    //     font-size: 16px;
    //     font-weight: 400;
    //     color: #333333;
    //     padding: 26px 36px;
    //     margin-bottom: 40px;
    // }

    .box {
        border-radius: 5px;
        padding: 20px 40px;
        border: 1px solid #EBEEF5;
        font-size: 16px;
        cursor: pointer;
        margin-bottom: 10px;
        box-shadow: 0px 2px 2px #c8c8c8;
    }

    // .SignOUT {
    //     margin-top: 10px;
    // }

    /deep/ .el-collapse {
        border: 1px solid #EBEEF5;
        padding: 0 37px;
    }

    /deep/.el-collapse-item__header {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
    }
}
</style>